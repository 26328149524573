import styled from "styled-components";

import home from "../../assets/img/home.jpg";
import asdfas from "../../assets/img/home.png";

export const Wrapper = styled.div`
  padding: 70px 50px;
  margin-top: 50px;
  height: 680px;
  background-image: url(${home});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  max-width: 100%;
  background-color: #7765B1;

  @media only screen and (max-width: 1024px) {
    height: 550px;
  }

  @media only screen and (max-width: 768px) {
    height: 550px;
    padding: 30px;
    margin-top: 0px;
  }
  @media only screen and (max-width: 400px) {
    height: 500px;
  }
  
  ul.list-unstyled.tamPadraoServico { font-size: 2rem !important; }

`;

export const Image = styled.div`
  /* border: 1px solid red; */
  background-image: url(${asdfas});
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;

  @media only screen and (max-width: 1024px) {
    background-size: 500px;
  }

  @media only screen and (max-width: 823px) {
    background-size: 400px;
  }

  @media only screen and (max-width: 768px) {
    background-size: 400px;
  }

  @media only screen and (max-width: 500px) {
    background-image: none;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 55px;
  color: #fff;

  @media only screen and (max-width: 768px) {
    font-size: 40px;
    margin-top: 20px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 40px;
  }
`;

export const Subtitle = styled.div`
  font-size: 20px;
  color: #fff;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Button = styled.div`
  background: #28a745;
  color: #fff;
  z-index: 7;
  white-space: nowrap;
  font-size: 18px;
  line-height: 49px;
  height: auto;
  font-weight: 300;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  padding: 0px 35px;
  border-color: rgb(0, 0, 0);
  border-radius: 30px;
  outline: none;
  box-shadow: rgb(153, 153, 153) 0px 0px 0px 0px;
  box-sizing: border-box;
  cursor: pointer;
  visibility: inherit;
  text-align: inherit;
  margin-top: 30px;
  letter-spacing: 0px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  opacity: 1;
  border-width: 0px;
  width: 200px;
  transition: 0.3s;

  :hover {
    background: #fff;
    color: #000;
  }
`;
