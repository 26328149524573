import React, { useState, useRef } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Form } from "@unform/web";
import * as Yup from "yup";
import crypto from "crypto";

import api from "../../service/api";
import Config from "../../config";
import { Button, Wrapper, Send, Cards } from "./styles";
import Loading from "../Loading";
import Input from "../../components/InputText";
import InputMask from "../../components/InputMask";

export default function ModalSubmit({ label, size, background }) {
  const [show, setShow] = useState(false);
  const [event, setEvent] = useState(false);
  const [course, setCourse] = useState(false);
  const [product, setProduct] = useState(false);
  const [valid, setValid] = useState(false);
  const [load, setLoad] = useState(false);
  const formRef = useRef(null);

  const handleClose = () => {
    setShow(false);
    setEvent(false);
    setCourse(false);
    setProduct(false);
  };
  const handleShow = () => setShow(true);

  function validate() {
    setValid(false);
    if (event) {
      return true;
    } else if (course) {
      return true;
    } else if (product) {
      return true;
    } else {
      setValid(true);
      return false;
    }
  }

  function encrypt(password) {
    const cipher = crypto.createCipher(
      Config.ENCRYPT.algorithm,
      Config.ENCRYPT.secret
    );
    cipher.update(password);
    return cipher.final(Config.ENCRYPT.type);
  }

  async function handleSubmit(data) {
    try {
      let type = event ? 1 : course ? 2 : product ? 3 : null;

      const submit = { ...data, tipo_eve: type };
      const hash = encrypt(submit.senha);

      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        email_membro: Yup.string()
          .email("Por favor, digite um e-mail valido.")
          .required("Por favor, digite seu e-mail."),
        nome_membro: Yup.string().required("Por favor, digite seu nome."),
        telefone: Yup.string().required("Por favor, digite seu telefone."),
        senha: Yup.string()
          .min(6, "A senha de ter 6 caracteres.")
          .required("Por favor, digite uma senha."),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      if (validate()) {
        setLoad(true);

        const response = await api.post("/auth/salvar-membro", submit);
        const { records } = response.data;
        if (records.error) {
          toast.error(records.msg);
          setLoad(false);
          setEvent(false);
          setCourse(false);
          setProduct(false);
          return;
        }

        window.location.href = `${
          process.env.NODE_ENV === "development"
            ? Config.URL_APP_DEVELOPMENT
            : Config.URL_APP_PRODUCTION
        }/login/${submit.email_membro}/${hash}`;
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <>
      <Button size={size} onClick={handleShow} background={background}>
        {label}
      </Button>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Wrapper>
          {load ? (
            <Loading />
          ) : (
            <Row>
              <Col className="text-center">
                <label
                  className="text-center"
                  style={{ fontWeight: 600, fontSize: "20px" }}
                >
                  O que você gostaria de promover?
                </label>
                <Form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  className="text-center"
                >
                  <Row>
                    <Col xs={4} className="p-2">
                      <Cards
                        onClick={() => {
                          setEvent(!event);
                          setCourse(false);
                          setProduct(false);
                        }}
                        className={`d-flex justify-content-center align-items-center flex-column ${
                          event ? "active" : ""
                        }`}
                      >
                        <i className="fa fa-calendar-alt fa-3x"></i>
                        <label style={{ fontWeight: 600, fontSize: "14px" }}>
                          Meu evento
                        </label>
                        <input
                          type="radio"
                          name="type"
                          value="evento"
                          checked={event}
                          onChange={() => setEvent(!event)}
                          style={{ cursor: "pointer" }}
                        />
                      </Cards>
                    </Col>
                    <Col xs={4} className="p-2">
                      <Cards
                        onClick={() => {
                          setCourse(!course);
                          setEvent(false);
                          setProduct(false);
                        }}
                        className={`d-flex justify-content-center align-items-center flex-column ${
                          course ? "active" : ""
                        }`}
                      >
                        <i className="fa fa-award fa-3x"></i>
                        <label style={{ fontWeight: 600, fontSize: "14px" }}>
                          Meu curso
                        </label>
                        <input
                          type="radio"
                          name="type"
                          value="curso"
                          checked={course}
                          onChange={() => setCourse(!course)}
                          style={{ cursor: "pointer" }}
                        />
                      </Cards>
                    </Col>
                    <Col xs={4} className="p-2">
                      <Cards
                        onClick={() => {
                          setProduct(!product);
                          setEvent(false);
                          setCourse(false);
                        }}
                        className={`d-flex justify-content-center align-items-center flex-column ${
                          product ? "active" : ""
                        }`}
                      >
                        <i className="fa fa-box-open fa-3x"></i>
                        <label style={{ fontWeight: 600, fontSize: "14px" }}>
                          Meu produto
                        </label>
                        <input
                          type="radio"
                          name="type"
                          value="produto"
                          checked={product}
                          onChange={() => setProduct(!product)}
                          style={{ cursor: "pointer" }}
                        />
                      </Cards>
                    </Col>
                    <Col className={valid ? "" : "d-none"}>
                      <span>Por favor, selecione uma opção</span>
                    </Col>
                  </Row>
                  <div className="form-group mt-3">
                    <Input
                      placeholder="Nome"
                      name="nome_membro"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <InputMask
                      mask="(99) 99999-9999"
                      placeholder="Seu Telefone (Whatsapp)"
                      name="telefone"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      placeholder="Seu e-mail"
                      name="email_membro"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      placeholder="Senha"
                      name="senha"
                      className="form-control"
                      type="password"
                    />
                  </div>
                  <Send>Próximo</Send>
                </Form>
                <p className="mt-2">
                  <small>
                    Já se cadastrou?{" "}
                    <span
                      style={{
                        color: "#7765B1",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open("http://app.psicomart.com.br")}
                    >
                      ENTRAR
                    </span>
                  </small>
                </p>
              </Col>
            </Row>
          )}
        </Wrapper>
      </Modal>
    </>
  );
}
