import React, { useRef, useCallback } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { toast } from "react-toastify";

import Input from "../../components/InputText";
import Textarea from "../../components/InputTextarea";

import psico from "../../service/psico";

import { Title, Subtitle, Button } from "./styles";

export default function Contact() {
  const formRef = useRef(null);

  const handleSubmit = useCallback(async (data, { reset }) => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        cto_email: Yup.string()
          .email("O e-mail digitado é invalido")
          .required("O e-mail é obrigatório"),
        cto_nome_pessoa: Yup.string().required("O nome é obrigatório"),
        cto_mensagem: Yup.string()
          .min(6, "A mensagem digitada é muito curta")
          .required("A mensagem é obrigatória"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      psico.post("/enviar-email-contato", data).then((response) => {
        console.log(response.data);
      });
      toast.success("Recebemos sua mensagem. Entraremos em contato em breve!");
      reset();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }, []);

  return (
    <section id="faq" style={{ marginTop: 100 }} className="animated fadeIn">
      <Container>
        <Row className="justify-content-md-center">
          <Col xl={12} className="flex-column">
            <Title>Entre em Contato</Title>
            <Subtitle className="text-muted">
              Quer saber mais sobre o PsicoMart?
            </Subtitle>
          </Col>

          <Col
            xl={6}
            className="d-flex justify-content-md-center flex-column mt-5"
          >
            <h5 className="mb-4">Formulario de contato</h5>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Nome</label>
                <Input name="cto_nome_pessoa" />
                <Input name="cto_aplicacao" value="9" type="hidden" />
                <Input name="cto_fone" value="" type="hidden" />
              </div>
              <div className="form-group">
                <label>E-mail</label>
                <Input name="cto_email" />
              </div>

              <div className="form-group">
                <label>Mensagem</label>
                <Textarea name="cto_mensagem" rows="3" />
              </div>
              <Button type="submit">Enviar</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
