import styled from "styled-components";

export const Wrapper = styled.div`
  background: #edecf0;
  margin-top: 30px;
`;

export const Links = styled.h3`
  color: #7765b1;
  font-weight: bold;
`;

export const Top = styled.button`
  position: fixed;
  bottom: -5px;
  right: 60px;
  z-index: 3;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #7765b1;
  color: white;
  text-decoration: none;
  cursor: pointer;
  padding: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  @media only screen and (max-width: 768px) {
    right: 5px;
  }
`;

export const Whats = styled.div`
  position: fixed;
  bottom: 20px;
  left: 25px;
  z-index: 3;
  background-color: #70bd62;
  color: white;
  padding: 7px 11px;
  cursor: pointer;
  border-radius: 10px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MarginTopFooter = styled.div`
  margin-top: 20px;
`;
