import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import { Wrapper, Title, Subtitle, Image } from "./styles";

import Modal from "../../components/Modal";
import Service from "../../pages/Service";
import Detail from "../../pages/Detail";
import Prince from "../../pages/Prince";

export default function Home() {
  return (
    <>
      <Wrapper id="home">
        <Container className="m-t-5" style={{ marginTop: 70 }}>
          <Image className="animated fadeIn">
            <Row className="animated fadeIn">
              <Col md={6} lg={6} xl={6}>
                <Title>
                  Promova seu evento, curso ou produto de Psicologia.
                </Title>
                <Subtitle>
                  Atraia mais clientes e participantes para o seu negócio com
                  uma ferramenta pronta para vendas.
                </Subtitle>

                <Modal label="Quero conhecer" />
              </Col>
            </Row>
          </Image>
        </Container>
      </Wrapper>
      <Service />
      <Detail />
      <Prince />
    </>
  );
}
