const Config = {
  URL_PRODUCTION: "https://apirs.virtux.com.br/v1/1090/rs",
  URL_DEVELOPMENT: "http://localhost/apirs/v1/1090/rs",
  URL_APP_DEVELOPMENT: "http://localhost:3001",
  URL_APP_PRODUCTION: "http://app.psicomart.com.br",
  ENCRYPT: {
    algorithm: "aes256",
    secret: "f3bc51923f71174a2178aa867fc27fdc",
    type: "hex",
  },
};

export default Config;
