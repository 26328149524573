import styled from "styled-components";

export const Wrapper = styled.div`
  height: auto;
  max-width: 100%;
  padding: 40px;

  .inputEmail {
    height: 35px;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    padding-left: 15px;
    border-radius: 90px;
    box-shadow: 0 0 25px 0 rgba(77, 54, 206, 0.2);
    padding-right: 15px;
    border: 1px solid #e4e4e4;

    :focus {
      border: 1px solid #7765b1;
      box-shadow: 0 0 25px 0 rgba(77, 54, 206, 0.2);
    }

    :hover {
      border: 1px solid #7765b1;
      box-shadow: 0 0 25px 0 rgba(77, 54, 206, 0.2);
    }
  }

  form span {
    color: red;
    font-size: 14px;
    justify-content: center;
  }
`;

export const Cards = styled.div`
  text-align: center;
  padding: 1rem;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  top: 0;
  background-color: #fff;
  position: relative;
  transition: all 0.5s ease-out 0s;
  cursor: pointer;

  label {
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
    cursor: pointer;
  }

  i {
    color: #33e2a0;
  }

  &.active {
    background: #7765b1;
    color: #fff;
    box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
    transform: scale(1.05);
  }

  :hover {
    background: #7765b1;
    color: #fff;
    box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const Send = styled.button`
  background: #28a745;
  color: #fff;
  white-space: nowrap;
  font-size: 16px;
  line-height: 40px;
  height: auto;
  font-weight: 600;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  padding: 0px 27px;
  border-color: rgb(0, 0, 0);
  border-radius: 30px;
  outline: none;
  box-shadow: rgb(153, 153, 153) 0px 0px 0px 0px;
  box-sizing: border-box;
  cursor: pointer;
  visibility: inherit;
  text-align: inherit;
  margin-top: 15px;
  border-width: 0px;
  width: 100%;
  transition: 0.3s;
  display: block;

  :hover {
    background: #7765b1;
    color: #000;
  }
`;

export const Button = styled.div`
  background: #28a745;
  color: #fff;
  z-index: 7;
  white-space: nowrap;
  font-size: ${(props) => (props.size ? "16px" : "18px")};
  line-height: ${(props) => (props.size ? "30px" : "49px")};
  height: auto;
  font-weight: 300;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  /* padding: 0px 35px; */
  border-color: rgb(0, 0, 0);
  border-radius: 30px;
  outline: none;
  box-shadow: rgb(153, 153, 153) 0px 0px 0px 0px;
  box-sizing: border-box;
  cursor: pointer;
  visibility: inherit;
  margin-top: ${(props) => (props.size ? "0" : "30px")};
  text-align: center;
  letter-spacing: 0px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  opacity: 1;
  border-width: 0px;
  width: ${(props) => (props.size ? "115px" : "200px")};
  transition: 0.3s;

  :hover {
    background: ${(props) => (props.background ? "#7765B1" : "#fff")};
    color: ${(props) => (props.background ? "#fff" : "#000")};
  }
`;
