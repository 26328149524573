import styled from "styled-components";

import home from "../../assets/img/03.png";

export const Wrapper = styled.div`
  margin-top: 40px;
  background-image: url(${home});
  background-repeat: no-repeat;
  background-position: left top;
  max-width: 100%;
`;

export const Navs = styled.ul`
  padding-left: 0;
  list-style: none;
  text-align: center !important;

  li {
    margin: 0;
    width: 50%;
    position: relative;
    box-shadow: 0 10px 36px 4px rgba(226, 217, 255, 0.3);
    float: left;
    background: #fff;
  }

  a {
    display: block;
    padding: 15px 0;
    color: #6f6f6f;
    text-decoration: none;
    background-color: transparent;
    transition: all 0.5s ease-out 0s;
    outline: medium;

    :hover {
      color: #7765B1;
    }

    :hover::before {
      display: inline-block;
      width: 100%;
      height: 3px;
      background: #7765B1;
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
`;
