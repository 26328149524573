import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { Wrapper, Links, Top, Whats } from "./styles";

export default function Footer() {
  const match = useHistory();

  const [top, setTop] = useState(false);

  const listenScrollEvent = (e) => {
    if (window.scrollY > 500) {
      setTop(true);
    } else {
      setTop(false);
    }
  };

  const scrollToPosition = useCallback((to) => {
    smoothScrollTo(0, to);
  }, []);

  const getScrollTopByHref = useCallback(
    (element) => {
      let id = element.getAttribute("href");
      if (match.location.pathname !== "/") {
        window.history.back();
        return 50;
      } else {
        console.log(document.querySelector(id).offsetTop);
        return document.querySelector(id).offsetTop;
      }
    },
    [match]
  );

  const scrollToIdOnClick = useCallback(
    (event) => {
      event.preventDefault();
      const to = getScrollTopByHref(event.currentTarget) - 80;
      scrollToPosition(to);
    },
    [getScrollTopByHref, scrollToPosition]
  );

  const scroll = useCallback(async () => {
    const selector = document.querySelectorAll('#contato a[href^="#"]');
    selector.forEach((item) => {
      item.addEventListener("click", scrollToIdOnClick);
    });
  }, [scrollToIdOnClick]);

  function smoothScrollTo(endX, endY, duration) {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    duration = typeof duration !== "undefined" ? duration : 400;

    const easeInOutQuart = (time, from, distance, duration) => {
      if ((time /= duration / 2) < 1)
        return (distance / 2) * time * time * time * time + from;
      return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = setInterval(() => {
      const time = new Date().getTime() - startTime;
      const newX = easeInOutQuart(time, startX, distanceX, duration);
      const newY = easeInOutQuart(time, startY, distanceY, duration);
      if (time >= duration) {
        clearInterval(timer);
      }
      window.scroll(newX, newY);
    }, 1000 / 60);
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    scroll();
  }, [scroll]);
  return (
    <Wrapper id="contato">
      <Container>
        {/*<Row
          style={{
            backgroundImage: `url(${require("../../assets/img/5.jpg")})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: 250
          }}
          className="p-2 text-light"
        >

          <Col className="d-flex justify-content-center align-items-center">
            <Row>
              <Col
                xl={6}
                md={12}
                lg={6}
                style={{ zIndex: 2 }}
                className="d-flex justify-content-center align-items-start flex-column"
              >
                <h1 className="font-weight-bold">Veja como é fácil</h1>
                <p>
                  Comece agora a receber os pagamentos de seus clientes.
                  Preencha seu e-mail para começar
                </p>
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <form>
                  <InputGroup>
                    <FormControl size="lg" placeholder="Preencha seu e-mail" />
                    <InputGroup.Append>
                      <Button variant="success">Cadastrar</Button>
                    </InputGroup.Append>
                  </InputGroup>
                </form>
              </Col>
            </Row>
          </Col>
        </Row>*/}
        <Row className="mt-5 pb-3">
          <Col md={6} className="mt-4">
            <ul className="list-unstyled">
              <li className="mt-2"> Responsável Técnico</li>
              <li className="mt-2"> Giovani Lucena - CRP 04/55806</li>
              <li className="mt-4">©Copyrights PsicoMart</li>
            </ul>
          </Col>
          <Col className="d-none d-lg-block mt-4">
            <ul className="list-unstyled text-muted">
              <li>
                <Links>Links</Links>
              </li>
              <a href="#home" className="text-muted text-decoration-none">
                <li className="mt-3">Home</li>
              </a>
              <NavLink
                to="/nosso-time"
                className="text-muted text-decoration-none"
              >
                <li className="mt-3">Nosso time</li>
              </NavLink>
              <NavLink to="/faq" className="text-muted text-decoration-none">
                <li className="mt-3">FAQ</li>
              </NavLink>
              <a href="#produtos" className="text-muted text-decoration-none">
                <li className="mt-3">Produtos</li>
              </a>
              <NavLink
                to="/contato"
                className="text-muted text-decoration-none"
              >
                <li className="mt-3">Contato</li>
              </NavLink>
            </ul>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      <div className={top ? "animated fadeIn" : "d-none"}>
        <a href="#home">
          <Top>
            <i className="fa fa-arrow-up"></i>
          </Top>
        </a>
      </div>
      <div className="animated fadeIn">
        <a
          href="https://wa.me/34996792007"
          rel="noopener noreferrer"
          target="_blank"
        >
          <Whats>
            <i className="fa fa-whatsapp fa-3x"></i>
          </Whats>
        </a>
      </div>
    </Wrapper>
  );
}
