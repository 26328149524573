import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Cards } from "./styles";

import Modal from "../../components/Modal";

export default function Prince() {
  return (
    <section id="planos">
      <Container>
        <Row className="justify-content-md-center">
          <Col xl={12} className="flex-column">
            <Title>
              {/* Você escolhe o plano que combina com você */}
              Nossos Preços
            </Title>
            {/*<Subtitle className="text-muted">Nossos Preços</Subtitle>*/}
          </Col>
          <Col md={6} className="mt-4">
            <Cards>
              <ul className="list-unstyled mt-4">
                {/* <li>
                  <h5 className="font-weight-bold">Por Venda</h5>
                </li>*/}
                <li>
                  <h2 className="font-weight-bold">Taxa a ser definida por transação</h2>
                </li>
                <li className="mt-5">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Receba direto na sua conta corrente
                </li>
                <li className="mt-3">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Gestão de seus recebimentos
                </li>
                <li className="mt-3">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Gestão de afiliados
                </li>
                <li className="mt-3">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Suporte
                </li>
                <li className="mt-3">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Cupons de desconto
                </li>
                <li className="mt-3">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Promovemos em nossas redes
                </li>
                <li className="d-flex justify-content-center mt-5">
                  <Modal label="Quero conhecer" background={true} />
                </li>
              </ul>
            </Cards>
          </Col>
          {/*
          <Col md={6} className="mt-4">
            <Cards>
              <ul className="list-unstyled">
                <li>
                  <h5 className="font-weight-bold">Apenas Hospedagem</h5>
                </li>
                <li>
                  <h2 className="font-weight-bold">12x de R$ 39,90</h2>
                </li>
                <li className="mt-5">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Receba direto na sua conta corrente
                </li>
                <li className="mt-3">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Gestão de seus recebimentos
                </li>
                <li className="mt-3">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Gestão de afiliados
                </li>
                <li className="mt-3">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Suporte
                </li>
                <li className="mt-3">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Cupons de desconto
                </li>
                <li className="mt-3">
                  <i className="far fa-check-circle mr-2 text-success"></i>
                  Promovemos em nossas redes
                </li>
                <li className="d-flex justify-content-center mt-5">
                  <Modal label="Quero conhecer"/>
                </li>
              </ul>
            </Cards>
          </Col>*/}
        </Row>
      </Container>
    </section>
  );
}
