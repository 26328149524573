import { createGlobalStyle } from "styled-components";

import "react-toastify/dist/ReactToastify.css";

export default createGlobalStyle`

  *{
    margin:0;
    padding:0;
    outline:0;
    box-sizing: border-box;
  }

  *:focus {
    outline:0;
  }

  html, body, #root {
    height: 100vh;
    font-family: 'Nunito', sans-serif;
  }

  body {
    -webkit-font-smoothing: antialiased;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #cecece;
    }

  }

  ::selection{
      background: #7765B1;
      color: #fff;
  }

  .active {
    font-weight: bold;
    color: #fff;
  }
`;
