import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";

import { Title, Subtitle, Figure } from "./styles";

export default function Team() {
  return (
    <section id="team" style={{ marginTop: 100 }} className="animated fadeIn">
      <Container>
        <Row className="justify-content-md-center">
          <Col xl={12} className="flex-column">
            <Title>Nosso Time</Title>
            <Subtitle className="text-muted">
              Talento vence jogos, mas trabalho em equipe e comprometimento
              ganham campeonatos
            </Subtitle>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Card className="d-flex justify-content-center align-items-center">
              <Figure
                width={190}
                className="img-fluid rounded-circle"
                src={require("../../assets/img/jose.png")}
                alt="CEO"
              />

              <Card.Body className="text-center">
                <h5>José Guilherme</h5>
                <small className="text-muted">CEO</small>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="d-flex justify-content-center align-items-center">
              <Figure
                width={190}
                className="img-fluid rounded-circle"
                src={require("../../assets/img/giovani.jpg")}
                alt="CEO"
              />

              <Card.Body className="text-center">
                <h5>Giovani Lucena</h5>
                <small className="text-muted">PSICÓLOGO</small>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="d-flex justify-content-center align-items-center">
              <Figure
                width={190}
                className="img-fluid rounded-circle"
                src={require("../../assets/img/gus.jpg")}
                alt="CEO"
              />

              <Card.Body className="text-center">
                <h5>Gustavo Freitas</h5>
                <small className="text-muted">DESENVOLVEDOR</small>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="d-flex justify-content-center align-items-center">
              <Figure
                width={190}
                className="img-fluid rounded-circle"
                src={require("../../assets/img/erik.jpg")}
                alt="CEO"
              />

              <Card.Body className="text-center">
                <h5>Erik Urbanski</h5>
                <small className="text-muted">DESENVOLVEDOR</small>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
