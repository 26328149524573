import styled from "styled-components";

export const Title = styled.h1`
  font-size: 45px;
  font-weight: 800;
  text-align: center;
`;

export const Subtitle = styled.h2`
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
`;

export const Cards = styled.div`
  text-align: center;
  padding: 1rem;
  border: 1px solid #f2f2f2;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  top: 0;
  background-color: #fff;
  position: relative;
  transition: all 0.5s ease-out 0s;

  :hover {
    margin-top: -5px;
    box-shadow: 0 0px 50px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const Button = styled.div`
  background: #28a745;
  color: #fff;
  z-index: 7;
  white-space: nowrap;
  font-size: 18px;
  line-height: 49px;
  height: auto;
  font-family: Nunito;
  padding: 0px 35px;
  border-color: rgb(0, 0, 0);
  border-radius: 30px;
  outline: none;
  box-shadow: rgb(153, 153, 153) 0px 0px 0px 0px;
  box-sizing: border-box;
  cursor: pointer;
  visibility: inherit;
  text-align: inherit;
  margin: 0px;
  letter-spacing: 0px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  opacity: 1;
  border-width: 0px;
  width: 200px;
  transition: 0.3s;

  :hover {
    background: #7765B1;
  }
`;
