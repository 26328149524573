import React, { useState, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Subtitle, AcordionTitle, AcordionDetail } from "./styles";

export default function Faq() {
  const [one, setOne] = useState(true);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);
  const [seven, setSeven] = useState(false);
  const [eight, setEight] = useState(false);

  const out = useCallback(() => {
    setOne(false);
    setTwo(false);
    setThree(false);
    setFour(false);
    setFive(false);
    setSix(false);
    setSeven(false);
    setEight(false);
  }, []);

  return (
    <section id="faq" style={{ marginTop: 100 }} className="animated fadeIn">
      <Container>
        <Row className="justify-content-md-center">
          <Col xl={12} className="flex-column">
            <Subtitle className="text-muted">FAQ</Subtitle>
            <Title>Perguntas Frequentes</Title>
          </Col>
          <Col xl={12}>
            <div>
              <AcordionTitle
                visible={one}
                onClick={() => {
                  out();
                  setOne(!one);
                }}
              >
                O que é o PsicoMart
              </AcordionTitle>
              <AcordionDetail
                visible={one}
                className={`${one && "animated fadeIn"}`}
              >
                <p>
                  Somos uma plataforma para impulsionar negócios de psicologia,
                  seja ele por venda de eventos, cursos e produtos. O PsicoMart
                  foi pensado para que produtores de eventos, cursos e afiliados
                  sejam beneficiados desde a publicação até a fase final da
                  venda.
                </p>
              </AcordionDetail>
            </div>
            <div>
              <AcordionTitle
                visible={two}
                onClick={() => {
                  out();
                  setTwo(!two);
                }}
              >
                Porque eu deveria usar o PsicoMart?
              </AcordionTitle>
              <AcordionDetail
                visible={two}
                className={`${two && "animated fadeIn"}`}
              >
                <p>
                  As novas tecnologias surgiram, e com isso, gerenciar e
                  promover o seu evento com agilidade e eficácia nunca foi tão
                  importante. Buscamos ser a alternativa que você precisa para
                  iniciar uma nova etapa de sucesso para sua carreira em
                  psicologia, se permitindo novas formas de arrecadação.
                </p>
              </AcordionDetail>
            </div>
            <div>
              <AcordionTitle
                visible={three}
                onClick={() => {
                  out();
                  setThree(!three);
                }}
              >
                Quais são as formas de pagamento aceitas?
              </AcordionTitle>
              <AcordionDetail
                visible={three}
                className={`${three && "animated fadeIn"}`}
              >
                <p>
                  O consumidor poderá pagar a partir de boleto ou cartão, e
                  dependendo das especificações do evento, também poderá
                  parcelar o valor final.
                </p>
              </AcordionDetail>
            </div>
            <div>
              <AcordionTitle
                visible={four}
                onClick={() => {
                  out();
                  setFour(!four);
                }}
              >
                É possível comprar mais de um ingresso, em nome de outra pessoa?
              </AcordionTitle>
              <AcordionDetail
                visible={four}
                className={`${four && "animated fadeIn"}`}
              >
                <p>
                  Sim, é possível comprar vários ingressos e nomeá-los
                  individualmente. Cada ingresso tem sua senha para acessar seu
                  ambiente individual.
                </p>
              </AcordionDetail>
            </div>
            <div>
              <AcordionTitle
                visible={five}
                onClick={() => {
                  out();
                  setFive(!five);
                }}
              >
                Como e quando receberei o valor referente às vendas do meu
                evento?
              </AcordionTitle>
              <AcordionDetail
                visible={five}
                className={`${five && "animated fadeIn"}`}
              >
                <p>
                  Oferecemos uma vez por mês o saque PsicoMart, que cairá
                  diretamente na sua conta bancária cadastrada.
                </p>
              </AcordionDetail>
            </div>
            <div>
              <AcordionTitle
                visible={six}
                onClick={() => {
                  out();
                  setSix(!six);
                }}
              >
                Qual o custo para utilizar o PsicoMart?
              </AcordionTitle>
              <AcordionDetail
                visible={six}
                className={`${six && "animated fadeIn"}`}
              >
                <p>
                  Ao criar eventos pagos, ficamos com uma taxa da venda realizada
                  de seu curso, evento ou produto. Neste valor já está incluso a
                  taxa de cartão de crédito, pix ou boleto, então não existe custo
                  adicional em utilizar o PsicoMart! O sucesso do seu evento é o
                  nosso sucesso
                </p>
              </AcordionDetail>
            </div>
            <div>
              <AcordionTitle
                visible={seven}
                onClick={() => {
                  out();
                  setSeven(!seven);
                }}
              >
                Como divulgar meu evento, curso ou produto nas redes sociais?
              </AcordionTitle>
              <AcordionDetail
                visible={seven}
                className={`${seven && "animated fadeIn"}`}
              >
                <p>
                  Ao criar o seu evento, você receberá um link para compartilhar
                  nas redes sociais, pronto para receber inscrições e começar a
                  faturar. Por este link que os consumidores irão comprar o seu
                  produto.
                </p>
              </AcordionDetail>
            </div>
            <div>
              <AcordionTitle
                visible={eight}
                onClick={() => {
                  out();
                  setEight(!eight);
                }}
              >
                Como saber se alguém comprou o meu ingresso?
              </AcordionTitle>
              <AcordionDetail
                visible={eight}
                className={`${eight && "animated fadeIn"}`}
              >
                <p>
                  Enviamos automaticamente um email para você a partir do
                  momento que o consumidor comprar o ingresso, avisando quem é a
                  pessoa e quantos ingressos ela comprou.
                </p>
              </AcordionDetail>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
