import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

export default function NotFound() {
  return (
    <section style={{ marginTop: 100 }}>
      <Container className="text-center">
        <img
          src={require("../../assets/img/8.png")}
          alt="Pagina não encontrada"
        />
        <h4>Página não foi encontrada!</h4>
        <Link to="/" className="btn btn-success">
          Voltar página inicial
        </Link>
      </Container>
    </section>
  );
}
