import React from "react";
import { Spinner } from "react-bootstrap";

export default function Loading() {
  return (
    <div className="d-flex justify-content-center align-items-center ">
      <Spinner animation="border" variant="primary" className="mr-2" />

      <span>
        Por favor aguarde<span className="animated fadeIn infinite">.</span>
        <span className="animated fadeIn infinity infinite">.</span>
        <span className="animated fadeIn infinity infinite">.</span>
      </span>
    </div>
  );
}
