import styled from "styled-components";

import detail from "../../assets/img/01.png";

export const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  background-image: url(${detail});
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
  max-width: 100%;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

export const Title = styled.h1`
  margin-top: 150px;
  font-size: 45px;
  font-weight: 800;
  text-align: center;
  @media only screen and (max-width: 768px) {
    margin-top: 50px;
  }
`;

export const Subtitle = styled.div`
  font-size: 20px;
  margin-top: 30px;
`;

export const Cards = styled.div`
  text-align: center;
  padding: 1rem;
  box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  top: 0;
  background-color: #fff;
  position: relative;
  transition: all 0.5s ease-out 0s;
  margin-bottom: 20px;

  label {
    font-weight: 700;
  }

  :hover {
    background: #7765B1;
    color: #fff;
    top: -10px;
    box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const Button = styled.div`
  background: #28a745;
  color: #fff;
  z-index: 7;
  white-space: nowrap;
  font-size: 18px;
  line-height: 49px;
  height: auto;
  font-weight: 300;
  color: rgb(255, 255, 255);
  font-family: Nunito;
  padding: 0px 35px;
  border-color: rgb(0, 0, 0);
  border-radius: 30px;
  outline: none;
  box-shadow: rgb(153, 153, 153) 0px 0px 0px 0px;
  box-sizing: border-box;
  cursor: pointer;
  visibility: inherit;
  text-align: inherit;
  margin: 0px;
  letter-spacing: 0px;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  opacity: 1;
  border-width: 0px;
  width: 200px;
  transition: 0.3s;

  :hover {
    background: #7765B1;
  }
`;

export const Icon = styled.div`
  background: 0 0;
  box-shadow: none;
  width: inherit;
  height: inherit;
  line-height: 80px;
  text-align: center;
  border-radius: 100%;
  display: inline-block !important;

  span {
    border: 1px dashed #28a745;
    height: 100px;
    width: 100px;
    display: inherit;
    border-radius: 100px;
  }

  i {
    background: rgba(124, 96, 213, 0.2);
    height: 80px;
    width: 80px;
    display: inline-block;
    line-height: 80px;
    border-radius: 100px;
    margin-top: 10px;
  }
`;
