import styled from "styled-components";

export const Title = styled.h1`
  font-size: 45px;
  font-weight: 800;
  text-align: center;
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
`;

export const Figure = styled.img`
  margin-top: 20px;
  box-shadow: 0 0.5rem 1rem rgba(22, 28, 45, 0.15) !important;
`;
