import React, { useEffect, useCallback } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";

import Modal from "../Modal";

export default function Header() {
  const match = useHistory();
  const scrollToPosition = useCallback((to) => {
    smoothScrollTo(0, to);
  }, []);

  const getScrollTopByHref = useCallback(
    (element) => {
      let id = element.getAttribute("href");
      if (match.location.pathname !== "/") {
        window.history.back();
        return 50;
      } else {
        console.log(document.querySelector(id).offsetTop);
        return document.querySelector(id).offsetTop;
      }
    },
    [match]
  );

  const scrollToIdOnClick = useCallback(
    (event) => {
      event.preventDefault();
      const to = getScrollTopByHref(event.currentTarget) - 80;
      scrollToPosition(to);
    },
    [getScrollTopByHref, scrollToPosition]
  );

  const scroll = useCallback(async () => {
    const selector = document.querySelectorAll('.menu a[href^="#"]');
    selector.forEach((item) => {
      item.addEventListener("click", scrollToIdOnClick);
    });
  }, [scrollToIdOnClick]);

  function smoothScrollTo(endX, endY, duration) {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    duration = typeof duration !== "undefined" ? duration : 400;

    const easeInOutQuart = (time, from, distance, duration) => {
      if ((time /= duration / 2) < 1)
        return (distance / 2) * time * time * time * time + from;
      return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = setInterval(() => {
      const time = new Date().getTime() - startTime;
      const newX = easeInOutQuart(time, startX, distanceX, duration);
      const newY = easeInOutQuart(time, startY, distanceY, duration);
      if (time >= duration) {
        clearInterval(timer);
      }
      window.scroll(newX, newY);
    }, 1000 / 60);
  }

  useEffect(() => {
    scroll();
  }, [scroll]);

  return (
    <Navbar
      fixed="top"
      collapseOnSelect
      expand="lg"
      variant="dark"
      style={{ background: "#7765B1" }}
    >
      <Container className="menu">
        <Navbar.Brand href="#home">
          <img
            src={require("../../assets/img/psicoMart1-mini.png")}
            alt="Psicomart"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="d-flex align-items-center">
            <Nav.Link href="#home" style={{ color: "#fff" }}>
              Home
            </Nav.Link>
            <Nav.Link href="#produtos" style={{ color: "#fff" }}>
              Produtos
            </Nav.Link>
            <Nav.Link href="#planos" style={{ color: "#fff" }}>
              Planos
            </Nav.Link>
            <NavLink
              to="/contato"
              style={{
                color: "#fff",
                paddingRight: ".5rem",
                paddingLeft: ".5rem",
                textDecoration: "none",
              }}
            >
              Contato
            </NavLink>
            <Nav.Link
              href="http://app.psicomart.com.br"
              target="_blank"
              style={{ color: "#fff" }}
            >
              Login
            </Nav.Link>

            <Modal label="Cadastrar" size="sm" />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
