import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import { Wrapper, Title, Subtitle, Icon, Cards } from "./styles";

import Modal from "../../components/Modal";

export default function Detail() {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col md={12} lg={6} className="mt-5">
            <Row className="mt-5">
              <Col md={6}>
                <Cards>
                  <Icon>
                    <span>
                      <i className="fa fa-hand-point-up fa-2x"></i>
                    </span>
                  </Icon>

                  <Card.Body>
                    <label>Simples e Intuitivo</label>
                    <Card.Text>
                      Em poucos minutos você já cria sua página e começa a
                      divulgar.
                    </Card.Text>
                  </Card.Body>
                </Cards>
              </Col>
              <Col md={6}>
                <Cards>
                  <Icon>
                    <span>
                      <i className="fa fa-funnel-dollar fa-2x"></i>
                    </span>
                  </Icon>
                  <Card.Body>
                    <label>Maior Conversão</label>
                    <Card.Text>
                      Cada detalhe pensado por especialistas em e-commerce.
                    </Card.Text>
                  </Card.Body>
                </Cards>
              </Col>
              <Col md={6}>
                <Cards>
                  <Icon>
                    <span>
                      <i className="fa fa-user-friends fa-2x"></i>
                    </span>
                  </Icon>

                  <Card.Body>
                    <label>De psicólogo para psicólogo</label>
                    <Card.Text>
                      Equipe formada por quem entende do público alvo.
                    </Card.Text>
                  </Card.Body>
                </Cards>
              </Col>
              <Col md={6}>
                <Cards>
                  <Icon>
                    <span>
                      <i className="fa fa-user-lock fa-2x"></i>
                    </span>
                  </Icon>

                  <Card.Body>
                    <label>Seguro e Ético</label>
                    <Card.Text>
                      Alto nível de segurança nas transações.
                    </Card.Text>
                  </Card.Body>
                </Cards>
              </Col>
            </Row>
          </Col>
          <Col>
            <Title>Porque o seu conhecimento vale muito!</Title>
            <Subtitle className="text-muted">
              Dê início a uma nova forma de empreender na psicologia! Com as
              novas tecnologias, a necessidade de se apresentar maneiras
              inovadoras se tornou fundamental, e o PsicoMart está aqui para lhe
              auxiliar nesta caminhada.
            </Subtitle>
            <Subtitle className="text-muted">
              Organizar e gerenciar um evento nunca foi tão fácil, e com o nosso
              sistema de afiliados, você tem a garantia que sua publicação terá
              um enorme alcance. Desta forma, produtores de eventos, cursos e
              afiliados serão beneficiados desde a publicação até a fase final
              da venda.
            </Subtitle>
            <div className="d-flex justify-content-center">
              <Modal label="Quero conhecer" background={true} />
            </div>
          </Col>
          <img
            src={require("../../assets/img/rocket.png")}
            alt=""
            className="img-fluid animated pulse infinite slow d-none d-lg-block"
            style={{ position: "absolute", right: "15%", marginTop: -130 }}
          />
        </Row>
      </Container>
    </Wrapper>
  );
}
