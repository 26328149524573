import React from "react";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Header from "./components/Header";
import Footer from "./components/Footer";

import GlobalStyle from "./styles/global";
import ScrollTop from "./components/ScrollTop";
import history from "./service/history";

import Routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";

function App() {
  return (
    <>
      <Router history={history}>
        <ScrollTop />
        <Header />
        <Routes />
        <Footer />
        <GlobalStyle />
        <ToastContainer autoClose={4000} />
      </Router>
    </>
  );
}

export default App;
