import styled from "styled-components";

export const Title = styled.h1`
  font-size: 45px;
  font-weight: 800;
  text-align: center;
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
  letter-spacing: 3px;
`;

export const AcordionTitle = styled.span`
  border-bottom: 1px solid #ececec;
  font-size: 20px;
  color: #1b0e3d;
  padding: 15px 15px 15px 0;
  float: left;
  width: 100%;
  position: relative;
  font-weight: 600;
  outline: medium none !important;
  transition: all 0.5s ease-out 0s;
  text-decoration: none;
  display: block;
  background-color: #fff;
  cursor: pointer;

  :hover {
    color: #33e2a0;
    text-decoration: none !important;
  }

  :before {
    cursor: pointer;
    content: '${(props) => (props.visible ? "\f056" : "\f055")}';
    font-family: "FontAwesome";
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    padding: 14px 0;
    color: ${(props) => (props.visible ? "#7765B1" : "#33e2a0")};
    font-size: 20px;
    line-height: 24px;
    height: 100%;
    font-weight: normal;
    -webkit-transition: all 0.25s ease-in-out 0s;
    -moz-transition: all 0.25s ease-in-out 0s;
    transition: all 0.25s ease-in-out 0s;
  }
`;

export const AcordionDetail = styled.div`
  float: left;
  width: 100%;
  padding: 30px 0;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 32px;
  color: #868894;
  letter-spacing: 1px;
  display: ${(props) => (props.visible ? "block" : "none")};

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;
