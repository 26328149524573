import React, { useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Wrapper, Navs } from "./styles";

export default function Service() {
  const scrollToPosition = useCallback((to) => {
    smoothScrollTo(0, to);
  }, []);

  const scrollToIdOnClick = useCallback(
    (event) => {
      event.preventDefault();
      const to = getScrollTopByHref(event.currentTarget) - 80;
      scrollToPosition(to);
    },
    [scrollToPosition]
  );

  const scroll = useCallback(async () => {
    const selector = document.querySelectorAll('.menus a[href^="#"]');
    selector.forEach((item) => {
      item.addEventListener("click", scrollToIdOnClick);
    });
  }, [scrollToIdOnClick]);

  function getScrollTopByHref(element) {
    const id = element.getAttribute("href");
    return document.querySelector(id).offsetTop;
  }

  function smoothScrollTo(endX, endY, duration) {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    duration = typeof duration !== "undefined" ? duration : 400;

    const easeInOutQuart = (time, from, distance, duration) => {
      if ((time /= duration / 2) < 1)
        return (distance / 2) * time * time * time * time + from;
      return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = setInterval(() => {
      const time = new Date().getTime() - startTime;
      const newX = easeInOutQuart(time, startX, distanceX, duration);
      const newY = easeInOutQuart(time, startY, distanceY, duration);
      if (time >= duration) {
        clearInterval(timer);
      }
      window.scroll(newX, newY);
    }, 1000 / 60);
  }

  useEffect(() => {
    scroll();
  }, [scroll]);
  return (
    <Wrapper id="produtos">
      <Container>
        <Row>
          <Col>
            <Navs className="d-flex justify-content-center menus">
              <li className="font-weight-bold">
                <a href="#evento">
                  <h5>Eventos</h5>
                </a>
              </li>
              <li className="font-weight-bold">
                <a href="#curso">
                  <h5>Cursos</h5>
                </a>
              </li>
              <li className="font-weight-bold">
                <a href="#produto">
                  <h5>Produtos</h5>
                </a>
              </li>
            </Navs>
          </Col>
        </Row>
        <Row className="mt-5" id="evento">
          <Col md={5} lg={6} className="d-flex align-items-center">
            <img
              src={require("../../assets/img/2.png")}
              alt=""
              className="img-fluid d-none d-md-block"
            />
          </Col>
          <Col md={7} lg={6}>
            <ul className="list-unstyled tamPadraoServico">
              <li className="media align-items-center p-2">
                <img
                  src={require("../../assets/img/events.png")}
                  alt=""
                  className="img-fluid mr-4"
                />
                <h4 className="font-weight-bold">Eventos</h4>
              </li>
              <li className="text-muted p-2 tamPadraoServico">
                Está promovendo um evento?
              </li>
              <li className="p-2 font-weight-bold tamPadraoServico">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Receba pagamentos de inscrições direto na conta
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Tenha afiliados para divulgar seu curso
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Ajudamos a divulgar em toda a nossa rede
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Tenha uma página para o seu evento
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Gerencie toda a base de inscritos
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mt-5" id="curso">
          <Col md={7} lg={6}>
            <ul className="list-unstyled">
              <li className="media align-items-center p-2">
                <img
                  src={require("../../assets/img/courses.png")}
                  alt=""
                  className="img-fluid mr-4"
                />
                <h4 className="font-weight-bold">Cursos</h4>
              </li>
              <li className="text-muted p-2">
                <h5>Ministra um curso e quer divulgar e atrair mais alunos?</h5>
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Receba pagamentos de inscrições direto na conta
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Crie seu roteiro
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Pague comissões a afiliados para divulgação
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Ajudamos a divulgar em toda nossa rede
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Tenha uma página própria para o seu curso
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Gerencie suas turmas
              </li>
            </ul>
          </Col>
          <Col md={5} lg={6} className="d-flex align-items-center">
            <img
              src={require("../../assets/img/07.png")}
              alt=""
              className="img-fluid d-none d-md-block"
            />
          </Col>
        </Row>
        <Row className="mt-5" id="produto">
          <Col md={5} lg={6} className="d-flex align-items-center">
            <img
              src={require("../../assets/img/portfolio.png")}
              alt=""
              className="img-fluid d-none d-md-block"
            />
          </Col>
          <Col md={7} lg={6}>
            <ul className="list-unstyled">
              <li className="media align-items-center p-2">
                <img
                  src={require("../../assets/img/products.png")}
                  alt=""
                  className="img-fluid mr-4"
                />
                <h4 className="font-weight-bold">Produtos</h4>
              </li>
              <li className="text-muted p-2">
                <h5>Você vende algum produto?</h5>
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Venda seus produtos digitais, físicos ou assinaturas
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Receba pagamento das vendas direto na conta
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Calcule frete de entrega para produto físico
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Ajudamos a divulgar em toda a nossa rede
              </li>
              <li className="p-2 font-weight-bold">
                <i className="far fa-check-circle mr-2 text-success"></i>
                Produto digital pode ser baixado após o pagamento confirmado
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}
