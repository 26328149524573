import axios from "axios";

import Config from "../config";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? Config.URL_DEVELOPMENT
      : Config.URL_PRODUCTION
});

export default api;
