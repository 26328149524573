import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../pages/Home";
import About from "../pages/About";
import Faq from "../pages/Faq";
import Team from "../pages/Team";
import Contact from "../pages/Contact";
import NotFound from "../pages/404";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/quem-somos" component={About} />
      <Route path="/faq" component={Faq} />
      <Route path="/nosso-time" component={Team} />
      <Route path="/contato" component={Contact} />

      <Route path="/" component={NotFound} />
    </Switch>
  );
}
